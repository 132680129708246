<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <section class="relative">
          <jublia-h1 class="DINBold text-jublia-bluest mb-6" style="font-weight: 598;">{{ $t('contact.title') }}</jublia-h1>
          <jublia-h2 class="text DINRegular mb-4" style="font-weight: 598;">{{ $t('contact.subTitle1') }}</jublia-h2>
          <jublia-p class="text DINRegular  mb-4" style="font-weight: 598;" v-html="$t('contact.text')" />
          <jublia-h2 class="text DINRegular mb-4" style="font-weight: 598;" v-html="$t('contact.subTitle2')" />
          <jublia-p class="text DINRegular" style="font-weight: 598;" v-html="$t('contact.textl')" />
           <!--<jublia-p class="text DINRegular" v-html="$t('contact.textll')" />-->
          <!--<img
            src="../assets/img/logo_bausch.jpg"
            class="icon-bausch-health"
            width="200"
            height="19"
          />-->
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import JubliaHeader from '@/components/header/jublia-header'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaH2 from '@/components/text/jublia-h2'
import JubliaP from '@/components/text/jublia-p'
  import { useI18n } from "vue3-i18n";

export default {
  components: {
    'jublia-header':JubliaHeader,
    'jublia-h2':JubliaH2,
    'jublia-h1':JubliaH1,
    'jublia-p':JubliaP,
   
  },
  setup() {
    const i18n = useI18n();
    //const getLocal= i18n.getLocale();
  
    const setLocale = () => {
      i18n.setLocale(i18n.getLocale() === 'fr' ? 'en' : 'fr');
     // console.log('ffff',i18n.getLocale())

    };

    return {
      setLocale,
      i18n
    };
  },
}
</script>

<style scoped>
.icon-bausch-health {
  @apply absolute right-0;
  bottom: -3rem;
}
.text {
  font-size: 1.1rem;
 
}
</style>
